import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import $ from 'jquery';
import AOS from 'aos/dist/aos';
import Footer from './components/Footer'
import Header from './components/Header'
import Home from './components/Home';
import About from './components/About';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import Mendleef from './components/Mendleef';

class App extends Component {

  aosInit() {
    AOS.init({
      duration: 1000,
      once: true
    });
  }

  componentDidMount() {
    $(window).on('load', function () {
      if ($('#preloader').length) {
        $('#preloader').delay(100).fadeOut('slow', function () {
          $(this).remove();
        });
      }
    });

    $(window).on('load', function () {
      AOS.init({
        duration: 1000,
        once: true
      });
      
      const select = (el, all = false) => {
        el = el.trim()
        if (all) {
          return [...document.querySelectorAll(el)]
        } else {
          return document.querySelector(el)
        }
      }
    
      /**
       * Easy event listener function
       */
        const on = (type, el, listener, all = false) => {
          let selectEl = select(el, all)
          if (selectEl) {
            if (all) {
              selectEl.forEach(e => e.addEventListener(type, listener))
            } else {
              selectEl.addEventListener(type, listener)
            }
          }
        }

        on('click', '.mobile-nav-toggle', function(e) {
          select('body').classList.toggle('mobile-nav-active')
          this.classList.toggle('bi-list');
          this.classList.toggle('bi-x');
        })
      
    });
    
    

  }

  render() {
    return (
      <Fragment>
        <Router>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about">
                            <About/>
            </Route>
            <Route exact path="/resume" component={Resume} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/mendleef" component={Mendleef} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </Router>
      </Fragment>
    )
  }
}

export default App;