import React from 'react'

const Element= props =>{
    return (
        <div className="col">
            {props.name}
        </div>
    )
}

export default Element;