import React, { Component, Fragment,useEffect } from 'react'
import {Link} from 'react-router-dom'

class Header extends Component {
    render() {
        return (
            <Fragment>
                    <button type="button" className="mobile-nav-toggle d-xl-none">
                        <i className="icofont-navigation-menu"></i>
                    </button>
                    <header id="header" className="d-flex flex-column justify-content-center">
                    <nav className="nav-menu">
                    <ul>
                        <Navlink to="/" iconClass="bx bx-home" pathName="Home"/>
                        <Navlink to="/about" iconClass="bx bx-user" pathName="About"/>
                        <Navlink to="/resume" iconClass="bx bx-file-blank" pathName="Resume"/>
                        <Navlink to="/contact" iconClass="bx bx-envelope" pathName="Contact"/>
                    </ul>
                    </nav>
                </header>
            </Fragment>
        )
    }
}
export default Header;

function Navlink(props) {
    return (
        <li className=""><Link to={props.to}><i className={`${props.iconClass}`}></i> <span>{props.pathName}</span></Link></li>
    )
}
