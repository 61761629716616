import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import '../css/Mendleef.css';
import Element from './Element';
import axios from 'axios';

class Mendleef extends Component {
    constructor(props) {

        super(props)

        this.state = {
            element: [],
            error: []
        }
    }

    componentDidMount() {
        axios.get('element.json')
            .then(res => {
                this.setState({element:res.Table.Row})
            }).catch(error => {

            })

    }

    render() {
        const { elmt }=this.state;
        return (
            <React.Fragment>
                <section id="resume" className="resume">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>Mendleef Latter</h2>
                        </div>
                        <div className="row">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Element name="mn" /></td>                                        
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default Mendleef;
